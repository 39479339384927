<script setup>
import { useSlideStore } from '@/stores/slideStore';
import { ref, onMounted } from "vue";

const slideStore = useSlideStore();
const summarySlide = ref(0); // To toggle between summary slides
const showComparisonImages = ref(false); // Toggle for EV vs ICE image comparison
const formatCurrency = (value) => {
  return Number(value).toLocaleString('en-US', { minimumFractionDigits: 2 });
};
// Set default selection on modal open
onMounted(() => {
  if (!slideStore.calculator.currentFleet.model) {
    slideStore.calculator.currentFleet.model = slideStore.iceModels[0].id;
  }
  if (!slideStore.calculator.evFleet.model) {
    slideStore.calculator.evFleet.model = slideStore.vehicles[0].id;
  }
});
</script>

<template>
  <transition name="fade">
    <div v-if="slideStore.showCalculator" class="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-20 px-4 py-8 overflow-y-auto">
      <div class="bg-gray-900 p-8 rounded-lg max-w-full w-full h-[90vh] md:h-5/6 overflow-y-auto mx-4 transform transition-all duration-500 animate-scale-up">
        <div class="flex flex-col md:flex-row justify-between items-center mb-4 text-center md:text-left">
          <h2 class="text-2xl md:text-3xl font-bold animate-fade-in">Fleet Cost Calculator</h2>
          <small>USD to GHS Conversion Rate: 1 @ {{ slideStore.conversionRate }}</small>
        </div>

        <!-- Small Side-by-Side Comparison Images (Rounded) -->
        <transition name="slide-fade">
          <div v-if="showComparisonImages" class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8 text-center">
            <div class="animate-slide-up">
              <h3 class="text-lg font-semibold text-white">ICE Vehicle</h3>
              <img :src="slideStore.selectedICE?.image" alt="ICE vehicle" class="w-24 h-24 md:w-32 md:h-32 rounded-full mx-auto object-cover">
            </div>
            <div class="animate-slide-up">
              <h3 class="text-lg font-semibold text-white">EV Vehicle</h3>
              <img :src="slideStore.selectedEV?.image" alt="EV vehicle" class="w-24 h-24 md:w-32 md:h-32 rounded-full mx-auto object-cover">
            </div>
          </div>
        </transition>

        <!-- Fleet Input Section -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <!-- ICE Fleet Section -->
          <div class="animate-fade-in">
            <h3 class="text-xl md:text-2xl mb-4">ICE Fleet</h3>
            <div class="space-y-4">
              <div>
                <label class="block mb-2">Select ICE Model</label>
                <select v-model="slideStore.calculator.currentFleet.model" class="w-full bg-gray-800 p-2 rounded">
                  <option v-for="model in slideStore.iceModels" :key="model.id" :value="model.id">
                    {{ model.name }} - GHS {{ formatCurrency(model.priceGHS) }}
                  </option>
                </select>
              </div>
              <div>
                <label class="block mb-2">Current Fuel Cost (GHS/liter)</label>
                <input v-model="slideStore.calculator.currentFleet.fuelCost" type="number" class="w-full bg-gray-800 p-2 rounded"/>
              </div>

              <!-- 3-Column Grid for Vehicles, Mileage, and Maintenance Cost -->
              <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
                <div>
                  <label class="block mb-2">Number of Vehicles</label>
                  <input v-model="slideStore.calculator.currentFleet.vehicles" type="number" class="w-full bg-gray-800 p-2 rounded"/>
                </div>
                <div>
                  <label class="block mb-2">Annual Mileage/Vehicle</label>
                  <input v-model="slideStore.calculator.currentFleet.mileage" type="number" class="w-full bg-gray-800 p-2 rounded"/>
                </div>
                <div>
                  <label class="block mb-2">Maintenance (Veh./10K KM)</label>
                  <input v-model="slideStore.calculator.currentFleet.maintenanceCost" type="number" class="w-full bg-gray-800 p-2 rounded"/>
                </div>
              </div>

              <div class="bg-gray-800 p-4 rounded mt-4">
                <h4 class="font-bold mb-2">Selected ICE Specifications</h4>
                <ul v-if="slideStore.selectedICE" class="space-y-1">
                  <li v-for="spec in slideStore.selectedICE.specs" :key="spec">{{ spec }}</li>
                </ul>
              </div>
            </div>
          </div>

          <!-- EV Fleet Section -->
          <div class="animate-fade-in">
            <h3 class="text-xl md:text-2xl mb-4">EV Fleet</h3>
            <div class="space-y-4">
              <div>
                <label class="block mb-2">Select EV Model</label>
                <select v-model="slideStore.calculator.evFleet.model" class="w-full bg-gray-800 p-2 rounded">
                  <option v-for="vehicle in slideStore.vehicles" :key="vehicle.id" :value="vehicle.id">
                    {{ vehicle.name }} - GHS {{ formatCurrency(vehicle.priceGHS) }}
                  </option>
                </select>
              </div>
              <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
                <div>
                  <label class="block mb-2">Public Charge (GHS/kWh)</label>
                  <input v-model="slideStore.publicChargeRate" type="number" step="0.01" class="w-full bg-gray-800 p-2 rounded" :disabled="!slideStore.isPublicCharging" />
                </div>
                <div>
                  <label class="block mb-2">Private Charge (GHS/kWh)</label>
                  <input v-model="slideStore.homeChargeRate" type="number" step="0.01" class="w-full bg-gray-800 p-2 rounded" :disabled="slideStore.isPublicCharging" />
                </div>
                <div>
                  <label class="block mb-2">Type of Charger</label>
                  <div class="flex">
                    <button @click="slideStore.isPublicCharging = true" :class="{ 'bg-green-600': slideStore.isPublicCharging }" class="px-4 py-2 rounded-lg bg-gray-700 text-white mr-2 transition-all transform hover:scale-105">Public</button>
                    <button @click="slideStore.isPublicCharging = false" :class="{ 'bg-green-600': !slideStore.isPublicCharging }" class="px-4 py-2 rounded-lg bg-gray-700 text-white transition-all transform hover:scale-105">Private</button>
                  </div>
                </div>
              </div>
              <div>
                <label class="block mb-2">Maintenance (Veh./10K KM)</label>
                <input v-model="slideStore.calculator.currentFleet.evMaintenanceCost" type="number" class="w-full bg-gray-800 p-2 rounded"/>
              </div>
              <div class="bg-gray-800 p-4 rounded">
                <h4 class="font-bold mb-2">Selected EV Specifications</h4>
                <ul v-if="slideStore.selectedEV" class="space-y-1">
                  <li v-for="spec in slideStore.selectedEV.specs" :key="spec">{{ spec }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-8">
          <h3 class="text-xl md:text-2xl font-bold mb-4 text-center">Cost and Savings Summary ({{slideStore.calculator.currentFleet.vehicles}} Vehicles)</h3>

          <div class="grid grid-cols-1 md:grid-cols-3 gap-4 w-full" v-if="summarySlide === 0">
            <div class="bg-gray-800 p-4 rounded text-center animate-slide-up">
              <p class="text-green-400">Annual Fuel Cost</p>
              <p class="text-2xl font-bold">GHS {{ slideStore.calculateFuelCost().toLocaleString() }}</p>
            </div>
            <div class="bg-gray-800 p-4 rounded text-center animate-slide-up">
              <p class="text-green-400">Annual Charging Cost</p>
              <p class="text-2xl font-bold">GHS {{ slideStore.calculateAnnualChargeCost().toLocaleString() }}</p>
            </div>
            <div class="bg-gray-800 p-4 rounded text-center animate-slide-up">
              <p class="text-green-400">Annual Savings</p>
              <p class="text-2xl font-bold">GHS {{ slideStore.calculateTotalFuelSavings().toLocaleString() }}</p>
            </div>
          </div>

          <!-- Summary Slide 2: Maintenance Costs and Savings -->
          <div class="grid grid-cols-1 md:grid-cols-3 gap-4 w-full" v-if="summarySlide === 1">
            <div class="bg-gray-800 p-4 rounded text-center animate-slide-up">
              <p class="text-green-400">Annual Maintenance (ICE)</p>
              <p class="text-2xl font-bold">GHS {{ slideStore.calculateMaintenanceCostICE().toLocaleString() }}</p>
            </div>
            <div class="bg-gray-800 p-4 rounded text-center w-full animate-slide-up">
              <p class="text-green-400">Annual Maintenance (EV)</p>
              <p class="text-2xl font-bold">GHS {{ slideStore.calculateMaintenanceCostEV().toLocaleString() }}</p>
            </div>
            <div class="bg-gray-800 p-4 rounded text-center w-full animate-slide-up">
              <p class="text-green-400">Annual Savings</p>
              <p class="text-2xl font-bold">GHS {{ slideStore.calculateTotalMaintenanceSavings().toLocaleString() }}</p>
            </div>
          </div>

          <!-- Summary Slide 3: Maintenance Costs and Savings -->
          <div class="grid grid-cols-1 md:grid-cols-4 gap-4 w-full" v-if="summarySlide === 2">
            <div class="bg-green-600 p-4 rounded text-center animate-slide-up">
              <p class="text-white">Total Fuel/Charge Savings</p>
              <p class="text-2xl font-bold text-white">GHS {{ slideStore.calculateTotalFuelSavings().toLocaleString() }}</p>
            </div>
            <div class="bg-green-600 p-4 rounded text-center w-full animate-pulse animate-slide-up">
              <p class="text-white">Total Maintenance Savings</p>
              <p class="text-2xl font-bold text-white">GHS {{ slideStore.calculateTotalMaintenanceSavings().toLocaleString() }}</p>
            </div>
            <div class="bg-green-600 p-4 rounded text-center w-full animate-pulse animate-slide-up">
              <p class="text-white">Total Savings</p>
              <p class="text-2xl font-bold text-white">GHS {{ slideStore.calculateTotalSavings().toLocaleString() }}</p>
            </div>
            <div class="bg-green-600 p-4 rounded text-center w-full animate-pulse animate-slide-up">
              <p class="text-white">ROI Period</p>
              <p class="text-2xl font-bold text-white">{{ slideStore.calculateROIPeriod()?.toLocaleString() ?? '0' }} years</p>
            </div>
          </div>

          <!-- Slide Buttons -->
          <div class="mt-4 flex justify-center space-x-4">
            <button v-for="i in 3" :key="i" @click="summarySlide = i - 1" class="px-3 py-1 rounded-full transition-all duration-200 transform" :class="{'bg-green-500 scale-110': summarySlide === i - 1, 'bg-gray-600': summarySlide !== i - 1}"></button>
          </div>

          <div class="mt-8 flex justify-center space-x-4">
            <button @click="slideStore.showVehicleModal = true" class="bg-green-500 hover:bg-green-600 text-white px-8 py-3 rounded-lg transition-all transform hover:scale-105 flex items-center">Show Vehicles</button>
            <button @click="slideStore.showCalculator = false; slideStore.reset()" class="bg-gray-500 hover:bg-green-600 text-white px-8 py-3 rounded-lg transition-all transform hover:scale-105 flex items-center">Close</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>
/* Animation for Scaling Up */
.animate-scale-up {
  opacity: 0;
  transform: scale(0.95);
  animation: scaleUp 0.5s ease forwards;
}
@keyframes scaleUp {
  0% { opacity: 0; transform: scale(0.95); }
  100% { opacity: 1; transform: scale(1); }
}

/* Slide-Up Animation */
.animate-slide-up {
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.6s ease forwards;
}
@keyframes slideUp {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* Fade-in Animation */
.animate-fade-in {
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
</style>

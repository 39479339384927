<script setup>
import { defineEmits } from 'vue';

const emit = defineEmits(['nextSlide']);
const nextSlide = () => {
  emit('nextSlide');
};
</script>

<template>
  <transition name="fade">
    <div class="fixed inset-0 bg-black bg-opacity-90 flex flex-col items-center justify-center z-20 px-4 py-8 overflow-y-auto">
      <div class="bg-gray-900 p-6 md:p-8 rounded-lg max-w-lg md:max-w-5xl w-full max-h-[calc(100vh-20px)] mx-auto animate-slide-up overflow-y-auto">
        <h2 class="text-2xl md:text-3xl font-bold mb-8 text-center animate-fade-in">Solar Charging Solution</h2>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <!-- Solar Infrastructure for Various Settings -->
          <div class="bg-gray-800 p-6 rounded-lg animate-slide-up">
            <h3 class="text-lg md:text-xl font-bold mb-4 text-green-400">Flexible Solar Charging Infrastructure</h3>
            <div class="space-y-4">
              <!-- Home and Residential Solutions -->
              <div class="flex items-start space-x-3">
                <svg class="w-6 h-6 text-green-500 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
                <div>
                  <h4 class="font-semibold">Home and Residential Solar Charging</h4>
                  <p class="text-sm text-gray-300">Empower your home with a self-sustaining solar charging station, ideal for EV owners seeking energy independence. Enjoy the convenience and cost savings of renewable energy right at your doorstep, while enhancing property value.</p>
                </div>
              </div>

              <!-- Commercial and Business Solutions -->
              <div class="flex items-start space-x-3">
                <svg class="w-6 h-6 text-green-500 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
                <div>
                  <h4 class="font-semibold">Commercial and Public Charging Infrastructure</h4>
                  <p class="text-sm text-gray-300">Our scalable solar solutions cater to businesses and public spaces, enabling high-efficiency charging stations that serve multiple users. Transform commercial areas into green hubs, maximizing energy savings and revenue potential.</p>
                </div>
              </div>
            </div>
          </div>

          <!-- Cost Benefits -->
          <div class="bg-gray-800 p-6 rounded-lg animate-slide-up">
            <h3 class="text-lg md:text-xl font-bold mb-4 text-green-400">Financial Benefits</h3>
            <div class="space-y-4">
              <div class="flex items-start space-x-3">
                <svg class="w-6 h-6 text-green-500 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <div>
                  <h4 class="font-semibold">Energy Cost Reduction</h4>
                  <p class="text-sm text-gray-300">Already have a solar system? You could reduce charging costs by up to 90%! And if you’re considering solar, you’ll not only power your chargers but also offset household electricity bills.</p>
                </div>
              </div>

              <div class="flex items-start space-x-3">
                <svg class="w-6 h-6 text-green-500 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                </svg>
                <div>
                  <h4 class="font-semibold">Increased ROI</h4>
                  <p class="text-sm text-gray-300">Integrating solar with your EV infrastructure significantly enhances your ROI, slashing fuel costs and elevating the value of your solar investment.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Savings Estimate with Hover Effect -->
        <div class="mt-8 bg-gray-800 p-6 rounded-lg animate-slide-up">
          <h3 class="text-lg md:text-xl font-bold mb-4 text-center">Estimated Annual Savings with Solar Charging</h3>
          <div class="grid grid-cols-1 md:grid-cols-3 gap-4 text-center">
            <div class="p-4 rounded transition-transform duration-200 hover:bg-green-700 hover:bg-opacity-40">
              <p class="text-green-400 mb-2">5 Vehicle Fleet</p>
              <p class="text-2xl font-bold">GHS 40,000+</p>
            </div>
            <div class="p-4 rounded transition-transform duration-200 hover:bg-green-700 hover:bg-opacity-40">
              <p class="text-green-400 mb-2">10 Vehicle Fleet</p>
              <p class="text-2xl font-bold">GHS 85,000+</p>
            </div>
            <div class="p-4 rounded transition-transform duration-200 hover:bg-green-700 hover:bg-opacity-40">
              <p class="text-green-400 mb-2">20+ Vehicle Fleet</p>
              <p class="text-2xl font-bold">GHS 180,000+</p>
            </div>
          </div>
        </div>

        <!-- Next Button -->
        <div class="mt-8 flex justify-center">
          <button
              @click="nextSlide"
              class="bg-green-500 hover:bg-green-600 px-8 py-3 rounded-lg transition-all transform hover:scale-105 flex items-center"
          >
            Calculate Your Savings
            <svg class="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>
/* Fade Transition */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Slide-Up Animation */
.animate-slide-up {
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.6s ease forwards;
}
@keyframes slideUp {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* Button Hover Effect */
button {
  transition: background-color 0.3s ease, transform 0.3s ease;
}
button:hover {
  background-color: #17a34a;
  transform: scale(1.1);
}

/* Grid Panel Hover Effect */
.grid div:hover {
  background-color: #175049;
  transform: scale(1.01);
}

/* Responsive overflow for mobile devices */
.fixed {
  max-height: 100vh;
  overflow-y: auto;
}
</style>

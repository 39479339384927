<script setup>
import { defineEmits } from 'vue'

const emit = defineEmits(['nextSlide']);
const nextSlide = () => {
  emit('nextSlide');
};
</script>
<template>
  <transition name="fade">
    <div class="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-20">
      <div class="bg-gray-900 p-8 rounded-lg max-w-4xl w-full mx-4 animate-slide-up">
        <h2 class="text-3xl font-bold mb-8 text-center animate-fade-in">Why Switch to Electric Vehicles?</h2>

        <!-- Content Grid with Slide-Up Animation -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div class="bg-gray-800 p-6 rounded-lg animate-slide-up">
            <h3 class="text-xl font-bold mb-4 text-green-400 animate-fade-in">Environmental Impact</h3>
            <ul class="space-y-3 animate-slide-up">
              <li class="flex items-center">
                <span class="w-2 h-2 bg-green-500 rounded-full mr-2"></span>
                Zero direct emissions
              </li>
              <li class="flex items-center">
                <span class="w-2 h-2 bg-green-500 rounded-full mr-2"></span>
                Reduced carbon footprint
              </li>
              <li class="flex items-center">
                <span class="w-2 h-2 bg-green-500 rounded-full mr-2"></span>
                Lower noise pollution
              </li>
              <li class="flex items-center">
                <span class="w-2 h-2 bg-green-500 rounded-full mr-2"></span>
                Support sustainable transportation
              </li>
            </ul>
          </div>
          <div class="bg-gray-800 p-6 rounded-lg animate-slide-up">
            <h3 class="text-xl font-bold mb-4 text-green-400 animate-fade-in">Economic Benefits</h3>
            <ul class="space-y-3 animate-slide-up">
              <li class="flex items-center">
                <span class="w-2 h-2 bg-green-500 rounded-full mr-2"></span>
                Lower operating costs
              </li>
              <li class="flex items-center">
                <span class="w-2 h-2 bg-green-500 rounded-full mr-2"></span>
                Reduced maintenance expenses
              </li>
              <li class="flex items-center">
                <span class="w-2 h-2 bg-green-500 rounded-full mr-2"></span>
                Modern and innovative technology
              </li>
              <li class="flex items-center">
                <span class="w-2 h-2 bg-green-500 rounded-full mr-2"></span>
                Instant torque and acceleration
              </li>
            </ul>
          </div>
        </div>

        <!-- Next Button with Hover Effect -->
        <div class="mt-8 flex justify-center">
          <button
              @click="nextSlide"
              class="bg-green-500 hover:bg-green-600 px-8 py-3 rounded-lg transition-all transform hover:scale-105 flex items-center"
          >
            Next
            <svg class="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>
<style scoped>
/* Fade Transition */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Slide-Up Animation */
.animate-slide-up {
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.6s ease forwards;
}
@keyframes slideUp {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* Button Hover Effect */
button {
  transition: background-color 0.3s ease, transform 0.3s ease;
}
button:hover {
  background-color: #17a34a;
  transform: scale(1.1);
}

/* Grid Panel Hover Effect */
.grid div:hover {
  background-color: #175049;
  transform: scale(1.01);
}
</style>

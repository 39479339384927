export const conversionRate = 16.3;
export const convertToGHS = (usdPrice) => parseFloat(usdPrice * conversionRate);


// EV Cars List
export const vehicles = [
    {
        id: 1,
        name: "Nami Air 430km",
        priceUSD: 18865,
        consumption: 15, // kWh/100km
        currentImageIndex: 0,
        specs: [
            "430 km range",
            "Electric Motor",
            "Power: 110 kW",
            "Energy Consumption: 15 kWh/100km",
            "Fast Charging Support"
        ],
        images: [
            "/images/nami-air-plus-cover.jpg",
            "/images/nami-air-plus-front.jpg",
            "/images/nami-air-plus-side.jpg",
            "/images/nami-air-plus-interior.jpg",
            "/images/nami-air-plus-back.jpg"
        ],
        get priceGHS() {
            return convertToGHS(this.priceUSD);
        }
    },
    {
        id: 2,
        name: "Neta Aya 2024",
        priceUSD: 18717,
        consumption: 16, // kWh/100km
        currentImageIndex: 0,
        specs: [
            "480 km range",
            "Electric Motor",
            "Power: 170 kW",
            "Energy Consumption: 16 kWh/100km",
            "0-100 km/h in 6.5s",
        ],
        images: [
            "/images/neta-aya-cover.jpg",
            "/images/neta-aya-front.jpg",
            "/images/neta-aya-side.jpg",
            "/images/neta-aya-interior.jpg",
            "/images/neta-aya-back.jpg"
        ],
        get priceGHS() {
            return convertToGHS(this.priceUSD);
        }
    },
];

// ICE Cars List
export const iceModels = [
    {
        id: 1,
        name: "Suzuki S-Presso",
        priceUSD: 15000,
        // priceGHS: 221100,
        fuelEfficiency: 6.2, // L/100km
        specs: ["400 km range", "1.0L Petrol Engine", "50 kW Power", "Fuel Consumption: 6.2 L/100km", "Top Speed: 155 km/h"],
        get priceGHS() {
            return convertToGHS(this.priceUSD);
        }
    },
];


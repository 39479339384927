<script setup>
import { defineEmits } from 'vue'
import {useSlideStore} from "@/stores/slideStore";

const slideStore = useSlideStore();

const emit = defineEmits(['startJourney']);

const startJourney = () => {
  emit('startJourney');
  slideStore.startJourney();
};
</script>
<template>
  <section class="relative h-screen flex items-center justify-center">
    <!-- Background Image -->
    <div
        class="absolute inset-0 bg-cover bg-center bg-no-repeat"
        style="background-image: url('/images/byd-interior.jpg')"
    ></div>

    <!-- Darkening Overlay -->
    <div class="absolute inset-0 bg-black bg-opacity-60"></div>

    <!-- Content -->
    <div class="relative z-10 text-center max-w-4xl px-4">
      <h1 class="text-5xl font-bold mb-6 animate-fade-in">
        Transform Your Business with Efficient Electric Solutions
      </h1>
      <p class="text-xl text-green-400 mb-8 animate-slide-up">
        Sustainable. Efficient. Future-Ready.
      </p>
      <button
          @click="startJourney"
          class="bg-green-500 hover:bg-green-600 text-white px-8 py-3 rounded-full text-lg transition-all transform hover:scale-105"
      >
        Start Your Journey
      </button>
      <div class="mt-12 text-center">
        <p class="text-sm text-gray-400 mb-4">Official Partners</p>
        <div class="flex items-center justify-center space-x-8">
          <img src="/images/logo/driveev.png" alt="DriveEV" class="h-12 opacity-80 hover:opacity-100 transition-opacity">
          <img src="/images/logo/plugdrive.png" alt="PlugDrive" class="h-12 opacity-80 hover:opacity-100 transition-opacity">
        </div>
      </div>
    </div>
  </section>
</template>

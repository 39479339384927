import { defineStore } from 'pinia';
import {ref, computed, watchEffect} from 'vue';
import {vehicles, iceModels, conversionRate} from '@/data/vehicles';
export const useSlideStore = defineStore('slideStore', () => {
    const currentSlide = ref(0);
    const showCalculator = ref(false);
    const showFleetQuestion = ref(false);
    const showVehicleModal = ref(false);
    const homeChargeRate = ref(3.50); // Cost per kWh at home in GHS
    const publicChargeRate = ref(5.00); // Cost per kWh at public charging stations in GHS
    const selectedView = ref('front'); // Track the current view for vehicle images
    const isPublicCharging = ref(true);

    const startJourney = () => {
        currentSlide.value = 1;
    };

    const nextSlide = () => {
        currentSlide.value++;
    };

    const selectVehicle = (vehicle) => {
        calculator.value.evFleet.model = vehicle.id;
        showVehicleModal.value = false;
        showCalculator.value = true;
    };

    const setFleetType = (type) => {
        showCalculator.value = true
        if (type === 'business') {
            calculator.value.currentFleet.mileage = 20000; // Set mileage for business
        } else {
            calculator.value.currentFleet.mileage = 10000; // Set mileage for individual
            calculator.value.currentFleet.vehicles = 1; // Set number of vehicles to 1 for individual
            showVehicleModal.value = true;
            return
        }

        showVehicleModal.value = !showCalculator.value;
    };

    const calculator = ref({
        currentFleet: {
            id: 1,
            model: 1,
            vehicles: 10,
            mileage: 20000,
            maintenanceCost: 3000, // per 10km
            evMaintenanceCost: 1000, // per 10km
            fuelCost: 14.4,
        },
        evFleet: {
            model: 1,
        },
    });

    const selectedEV = computed(() => vehicles.find(vehicle => vehicle.id === calculator.value.evFleet.model));
    const selectedICE = computed(() => iceModels.find(model => model.id === calculator.value.currentFleet.model));

    const getCurrentICEImage = () => selectedICE.value?.images[selectedView.value];
    const getCurrentEVImage = () => selectedEV.value?.images[selectedView.value];

    const calculateFuelCost = () => {
        const efficiency = selectedICE.value?.fuelEfficiency || 12;
        return calculator.value.currentFleet.vehicles *
            calculator.value.currentFleet.mileage *
            (calculator.value.currentFleet.fuelCost / efficiency);
    };

    const calculateAnnualChargeCost = () => {
        const consumption = selectedEV.value?.consumption || 18.5;
        const chargeRate = isPublicCharging.value ? publicChargeRate.value : homeChargeRate.value;
        const mileage = calculator.value.currentFleet.mileage;
        const per10kKmCost = ((consumption / 100) * chargeRate * 10000); // Cost per 10,000 KM
        return calculator.value.currentFleet.vehicles * (mileage / 10000) * per10kKmCost;
    };

    // Changed to regular functions
    const calculateMaintenanceCostICE = () => {
        const mileage = calculator.value.currentFleet.mileage;
        const costPerUnit = calculator.value.currentFleet.maintenanceCost / 10000; // Adjusted cost per 10,000 km
        return calculator.value.currentFleet.vehicles * (costPerUnit * mileage);
    };

    const calculateMaintenanceCostEV = () => {
        const mileage = calculator.value.currentFleet.mileage;
        const costPerUnit = calculator.value.currentFleet.evMaintenanceCost / 10000; // Adjusted cost per 10,000 km for EV
        return calculator.value.currentFleet.vehicles * (costPerUnit * mileage);
    };

    const calculateTotalFuelSavings = () => {
        return calculateFuelCost() - calculateAnnualChargeCost();
    };

    const calculateTotalMaintenanceSavings = () => {
        return calculateMaintenanceCostICE() - calculateMaintenanceCostEV();
    };

    const calculateTotalSavings = () => {
        return calculateTotalFuelSavings() + calculateTotalMaintenanceSavings();
    };

    const reset = () => {
        showCalculator.value = false;
        showVehicleModal.value = false;
        currentSlide.value = 0;
        calculator.value.currentFleet = {
            id: 1,
            model: 1,
            vehicles: 10,
            mileage: 10000,
            maintenanceCost: 3000, // per 10km
            evMaintenanceCost: 1000, // per 10km
            fuelCost: 14.4,
        }
        calculator.value.evFleet.model = 1;
    };

    const closeVehicleModal = () => {
        showVehicleModal.value = false;
        // reset();
    };

    const calculateFuelSavings = () => calculateFuelCost() - calculateAnnualChargeCost();

    const calculateHomeChargingCost = () => {
        const consumption = selectedEV.value?.consumption || 18.5;
        return calculator.value.currentFleet.mileage * (consumption / 100) * homeChargeRate.value;
    };

    const calculatePublicChargingCost = () => {
        const consumption = selectedEV.value?.consumption || 18.5;
        return calculator.value.currentFleet.mileage * (consumption / 100) * publicChargeRate.value;
    };

    // NEED TO LOOK AT CALCULATIONS - MAY NEED 3 of THESEE
    const calculateROIPeriod = () => {
        if (
            selectedEV.value &&
            selectedICE.value &&
            typeof selectedEV.value.priceGHS === 'number' &&
            typeof selectedICE.value.priceGHS === 'number' &&
            calculateTotalSavings() > 0
        ) {
            // Calculate the cost difference between EV and ICE vehicles in GHS
            // const costDifferenceGHS = selectedEV.value.priceGHS - selectedICE.value.priceGHS;
            const costDifferenceGHS = selectedEV.value.priceGHS - (selectedEV.value.priceGHS - selectedICE.value.priceGHS);
            const annualSavings = calculateTotalSavings();

            // Calculate ROI period
            return costDifferenceGHS / annualSavings;
        }

        console.warn("Calculation error: Missing or invalid values.");
        return null;
    };


    watchEffect(() => {
        calculateMaintenanceCostICE();
        calculateMaintenanceCostEV();
        calculateTotalMaintenanceSavings();
        calculateTotalSavings();
    });

    return {
        startJourney,
        nextSlide,
        selectVehicle,
        setFleetType,
        showVehicleModal,
        showFleetQuestion,
        showCalculator,
        currentSlide,
        calculator,
        vehicles,
        selectedEV,
        selectedICE,
        homeChargeRate,
        publicChargeRate,
        reset,
        closeVehicleModal,
        getCurrentICEImage,
        getCurrentEVImage,
        selectedView,
        iceModels,
        calculateFuelCost,
        calculateAnnualChargeCost,
        calculateFuelSavings,
        calculateHomeChargingCost,
        calculatePublicChargingCost,
        calculateMaintenanceCostICE,
        calculateMaintenanceCostEV,
        calculateTotalSavings,
        calculateTotalFuelSavings,
        calculateTotalMaintenanceSavings,
        isPublicCharging,
        conversionRate,
        calculateROIPeriod
    };
});

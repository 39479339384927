<template>
  <div class="min-h-screen bg-black text-white">
    <HeroFrontPage v-if="slideStore.currentSlide === 0" @startJourney="startJourneyAction" />

    <transition name="fade">
      <WhyElectric v-if="slideStore.currentSlide === 1" @nextSlide="nextSlide" />
    </transition>

    <transition name="fade">
      <SolarSolution v-if="slideStore.currentSlide === 2" @nextSlide="nextSlide" />
    </transition>

    <transition name="fade">
      <BusinessOrPerson v-if="slideStore.currentSlide === 3" />
    </transition>

    <transition name="fade">
      <Calculator v-if="slideStore.showCalculator" />
    </transition>

    <transition name="fade">
      <Vehicles v-if="slideStore.showVehicleModal" />
    </transition>
  </div>
</template>

<script setup>
import { useSlideStore } from '@/stores/slideStore';
import HeroFrontPage from '@/components/HeroFrontPage.vue';
import WhyElectric from '@/components/WhyElectric.vue';
import SolarSolution from "@/components/SolarSolution.vue";
import BusinessOrPerson from "@/components/BusinessOrPerson.vue";
import Calculator from "@/components/CalculatorSlide.vue";
import Vehicles from "@/components/VehiclesSlide.vue";

const slideStore = useSlideStore();

const startJourneyAction = () => {
  slideStore.currentSlide = 1;
};

const nextSlide = () => {
  slideStore.currentSlide++;
};
</script>

<script setup>
import { useSlideStore } from '@/stores/slideStore';

const slideStore = useSlideStore();
const { setFleetType } = slideStore;
</script>

<template>
  <transition name="fade">
    <div class="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-20">
      <div class="bg-gray-900 p-8 rounded-lg max-w-md w-full mx-4 transform transition-all">
        <h2 class="text-2xl font-bold mb-6 text-center">What best describes you?</h2>
        <div class="space-y-4">
          <button
              @click="setFleetType('business')"
              class="w-full bg-green-500 hover:bg-green-600 py-3 rounded-lg transition-all transform hover:scale-105"
          >
            I manage a business fleet
          </button>
          <button
              @click="setFleetType('individual')"
              class="w-full bg-gray-700 hover:bg-gray-600 py-3 rounded-lg transition-all transform hover:scale-105"
          >
            I'm an individual
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

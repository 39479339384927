<script setup>
import { useSlideStore } from '@/stores/slideStore';
import { ref, onBeforeUnmount } from 'vue';

const slideStore = useSlideStore();

const closeModal = () => {
  slideStore.closeVehicleModal();
  slideStore.showCalculator = true;
};

const imageIntervals = ref([]);

const startImageSlide = (index) => {
  slideStore.vehicles[index].currentImageIndex = 0;

  imageIntervals.value[index] = setInterval(() => {
    const vehicle = slideStore.vehicles[index];
    const imageElement = document.getElementById(`vehicle-image-${vehicle.id}`);

    if (imageElement) {
      imageElement.style.transition = 'opacity 0.5s';
      imageElement.style.opacity = '0';
    }

    setTimeout(() => {
      vehicle.currentImageIndex = (vehicle.currentImageIndex + 1) % vehicle.images.length;

      setTimeout(() => {
        if (imageElement) {
          imageElement.style.opacity = '1';
        }
      }, 100); // Delay before starting fade-in

    }, 500); // Duration of fade-out before index change

  }, 2000); // Overall interval duration for each image cycle
};


const stopImageSlide = (index) => {
  clearInterval(imageIntervals.value[index]);
};

onBeforeUnmount(() => {
  imageIntervals.value.forEach(clearInterval);
});
</script>

<template>
  <transition name="fade">
    <div v-if="slideStore.showVehicleModal" class="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-20">
      <div class="bg-gray-900 p-8 rounded-lg max-w-6xl w-full mx-4 h-3/4 overflow-y-auto">
        <h2 class="text-3xl font-bold mb-8 text-center">Available Electric Vehicles</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div
              v-for="(vehicle, index) in slideStore.vehicles"
              :key="vehicle.id"
              class="bg-gray-800 rounded-lg overflow-hidden transform transition-all hover:scale-105"
              @mouseenter="startImageSlide(index)"
              @mouseleave="stopImageSlide(index)"
          >
            <img
                :id="'vehicle-image-' + vehicle.id"
                :src="vehicle.images[vehicle.currentImageIndex] || vehicle.images[0]"
                :alt="vehicle.name"
                class="w-full h-48 object-cover"
            />
            <div class="p-6">
              <h3 class="text-xl font-bold mb-2">{{ vehicle.name }}</h3>
              <p class="text-green-400 mb-4">Starting from GHS {{ vehicle.priceGHS.toLocaleString() }}</p>
              <ul class="space-y-2 mb-6">
                <li v-for="spec in vehicle.specs" :key="spec">{{ spec }}</li>
              </ul>
              <button
                  @click="slideStore.selectVehicle(vehicle)"
                  class="w-full bg-green-500 hover:bg-green-600 py-2 rounded transition-all transform hover:scale-105"
              >
                Calculate Savings
              </button>
            </div>
          </div>
        </div>
        <div class="mt-8 text-center">
          <button
              @click="closeModal"
              class="bg-gray-700 hover:bg-gray-600 px-6 py-2 rounded-lg transition-all transform hover:scale-105"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>
